import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import Navbar from "/src/containers/navbar";

import { useDispatch } from "react-redux";
import SpaceCompleteContainer from "/src/containers/space-complete";
import { LocalizedRouter as Router } from "gatsby-theme-i18n"

import api from "/src/api";
import { useParams } from "@reach/router";
import { transformSpace, INITIAL_STATE } from "/src/redux/reducers/space-reducer";
import { setCurrentAsset } from "/src/redux/actions/misc-actions";

const SpaceComplete = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [space, setSpace] = useState(INITIAL_STATE.data);

  useEffect(async () => {
    const spaceId = params.spaceId ? params.spaceId.substr(0, params.spaceId.indexOf("-")) : "1";
    const response = await api.getSpace(spaceId)
    dispatch(setCurrentAsset(response.data.payload.asset));
    setSpace(transformSpace(response.data.payload))
    //dispatch current asset to set proper styles
  }, [])

  return <SpaceCompleteContainer space={space} />
}

const SpaceUnindexed = () => {

  
  return (
    <>
      <BacThemeProvider>
        <Navbar />
        <Router basePath={"/"}>
          <SpaceComplete path={"space/:spaceId"} />
        </Router>
      </BacThemeProvider>
    </>
  )
}

export default SpaceUnindexed
